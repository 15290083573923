<template>
  <v-dialog v-model="open" width="800" persistent>
    <v-card v-if="open">
      <template v-if="closingShift">
        <v-card-text class="pa-3">
          <v-layout justify-center align-center>
            <v-progress-circular indeterminate color="primary" />
          </v-layout>
        </v-card-text>
      </template>
      <template v-else-if="shiftSummary">
        <v-card-title class="headline">
          <div class="break-word">{{$t('shifts.close_shift.title', { operator: operatorName, device: printerName })}}</div>
        </v-card-title>
        <v-card-text class="text-center">
          <v-form class="pa-2" ref="form" v-if="paymentMethods && currencies">
            <v-layout row wrap v-for="(paymentMethod, i) in paymentMethods" :key="i">
              <v-flex xs12 class="caption">{{paymentMethod.name}}</v-flex>
              <v-flex xs12 class="caption my-2">
                <v-layout row wrap>
                  <v-flex xs4 class="text-right">
                    {{$t('shifts.close_shift.initial')}}
                  </v-flex>
                  <v-flex xs4 class="text-right">
                    {{$t('shifts.close_shift.final')}}
                  </v-flex>
                  <v-flex xs4 class="text-right">
                    {{$t('shifts.close_shift.difference')}}
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 v-for="currency in currencies" :key="currency.id" class="pa-2">
                <v-layout row wrap>
                  <v-flex xs4 class="title text-right">
                    {{formatAmount(shiftSummary.initialAccountingStateMapped[`${paymentMethod.id}.${currency.id}`] || 0)}} <span class="font-weight-thin">{{currency.name}}</span>
                  </v-flex>
                  <v-flex xs4 class="title text-right">
                    {{formatAmount(shiftSummary.finalAccountingStateMapped[`${paymentMethod.id}.${currency.id}`] || 0)}} <span class="font-weight-thin">{{currency.name}}</span>
                  </v-flex>
                  <v-flex xs4 class="title text-right d-flex">
                    <v-icon>mdi-menu-right</v-icon>
                    <v-spacer/>
                    {{formatAmount((shiftSummary.finalAccountingStateMapped[`${paymentMethod.id}.${currency.id}`] || 0) - (shiftSummary.initialAccountingStateMapped[`${paymentMethod.id}.${currency.id}`] || 0))}}<span class="font-weight-thin show-spaces"> {{currency.name}}</span>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-form>
          <v-progress-circular v-else indeterminate color="secondary" />
        </v-card-text>
        <template v-if="gettingShiftTicket">
          <v-card-text class="pa-3">
            <v-layout justify-center align-center>
              <v-progress-circular indeterminate color="primary" />
              {{$t('shifts.close_shift.getting_ticket')}}
            </v-layout>
          </v-card-text>
        </template>
        <v-card-actions v-if="failedTicketGeneration || failedPrinting">
          <v-layout column>
            <div class="body-2 text-center"><v-icon color="warning" class="mr-2">mdi-alert-outline</v-icon>{{$t('shifts.close_shift.error.ticket')}}</div>
            <v-layout row justify-center class="ma-0">
              <v-btn text v-if="failedPrinting" color="primary" @click="printShiftTicket()" class="mt-3">{{$t('actions.retry')}}</v-btn>
              <v-btn text v-if="failedTicketGeneration" color="primary" @click="getShiftTicket()" class="mt-3">{{$t('actions.retry')}}</v-btn>
            </v-layout>
          </v-layout>
        </v-card-actions>
        <v-card-actions>
          <v-layout column>
            <v-layout row justify-end class="ma-0">
              <v-btn text color="primary" @click="open = false" class="mt-3">{{$t('actions.close')}}</v-btn>
            </v-layout>
          </v-layout>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { ShiftRequests } from '@/services/shift.requests'
import moneyFormatMixins from '@/mixins/MoneyFormat'
import { mapGetters, mapState } from 'vuex'
import printerMixins from '@/mixins/PrinterPeripheralActions'
import peripheralMixins from '@/mixins/PeripheralSocket'
const shiftService = new ShiftRequests()

export default {
  // EMMITED EVENTS: closeShift, isLoading
  props: {
    triggerOpen: Date,
    shiftId: String,
    inNavigationMenu: Boolean
  },
  data () {
    return {
      open: false,
      printerName: null,
      closingShift: false,
      shiftSummary: null,
      base64Ticket: null,
      failedPrinting: false,
      failedTicketGeneration: false,
      gettingShiftTicket: false
    }
  },
  mixins: [
    moneyFormatMixins,
    printerMixins,
    peripheralMixins
  ],
  computed: {
    ...mapState({
      state: state => state.state,
      shifts: state => state.shifts,
      operatorShift: state => state.shifts.operatorShift
    }),
    ...mapGetters({
      currencies: 'configuration/currencies',
      paymentMethodIds: 'configuration/paymentMethodIds'
    }),
    paymentMethods () {
      return this.paymentMethodIds && this.$store.state.paymentMethods.list.length ? this.paymentMethodIds.map((id) => {
        return this.$store.state.paymentMethods.list.find((pM) => {
          return pM.id === id
        })
      }) : []
    },
    operatorName () {
      return this.shiftSummary ? this.$store.state.auth.credentials.operator.name : '-'
    }
  },
  methods: {
    printShiftTicket () {
      this.failedPrinting = false
      this.sendPrinterAction(this.base64Ticket, 'application/pdf', () => {}, () => {
        this.failedPrinting = true
      })
    },
    getShiftTicket () {
      this.gettingShiftTicket = true
      this.failedTicketGeneration = false
      shiftService.getShiftTicket(this.shiftId).then((base64Pdf) => {
        this.base64Ticket = base64Pdf
        this.printShiftTicket()
      }).catch((error) => {
        this.failedTicketGeneration = true
      }).finally(() => {
        this.gettingShiftTicket = false
      })
    }
  },
  watch: {
    triggerOpen () {
      this.open = true
      this.closingShift = true
      this.$emit('isLoading', true)
      shiftService.close({ shiftId: this.shiftId }).then((shiftSummary) => {
        this.$store.dispatch('shifts/reloadShifts')
        this.$emit('closeShift')
        this.getShiftTicket()
        this.shiftSummary = shiftSummary
        var initialAccountingState = {}, finalAccountingState = {}
        this.shiftSummary.initialAccountingState.forEach(value => {
          initialAccountingState[`${value.paymentMethodId}.${value.currencyId}`] = value.amount
        })
        this.shiftSummary.finalAccountingState.forEach(value => {
          finalAccountingState[`${value.paymentMethodId}.${value.currencyId}`] = value.amount
        })
        this.shiftSummary.initialAccountingStateMapped = initialAccountingState
        this.shiftSummary.finalAccountingStateMapped = finalAccountingState
        var printerId = (localStorage.getItem('selectedPrinter') !== this.shiftSummary.printerId) ? this.shiftSummary.printerId : localStorage.getItem('selectedPrinter')
        this.$store.dispatch('peripherals/get', { id: printerId }).then((peripheral) => {
          this.printerName = peripheral ? (peripheral.label || peripheral.model || peripheral.id) : null
        })
      }).catch(() => {
        this.open = false
      }).finally(() => {
        this.$emit('isLoading', false)
        this.closingShift = false
      })
      this.$store.dispatch('paymentMethods/list')
    },
    open () {
      if (!this.open) {
        // Reset values
      }
    }
  }
}
</script>

<style lang="scss">
.v-text-field .v-input__append-inner, .v-text-field .v-input__prepend-inner {
  margin-top: auto;
  margin-bottom: auto;
  min-width: fit-content;
}
.v-input.price input {
  text-align: right;
}
</style>